import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender, QueryObjectGrid, RenderDetail } from "./Common";

export interface AdminManagerFlow {
  idFlusso: string;
  descrizione: string;
}
export interface AdminManagerDetail {
  idFlusso: string;
  id: string;
  country: string;
  brand: string;
  maprftpOutputTemplate1: string;
  maprftpOutputTemplate2: string;
  gcpftpOutputTemplate1: string;
  gcpftpOutputTemplate2: string;
  contactHistory: string;
  maprBlobStorageInput: string;
  gcpBlobStorageInput: string;
  blobInputFolder: string;
}

export interface AdminManagerDetailQuery extends QueryObjectGrid {
  idFlusso?: string;
  brand?: string[];
  country?: string[];
  id?: string;
  //idServerFTP?: string;
}

export interface AdminManagerFlowQueryResultDto {
  totalItems?: number;
  items?: AdminManagerFlow[];
  gridRender?: CustomGridRender;
}
export interface AdminManagerDetailQueryResultDto {
  totalItems?: number;
  items?: AdminManagerDetail[];
  gridRender?: CustomGridRender;
}

export interface AdminManagerDetailGrid {
  AdminManagerDetailGridResult: AdminManagerDetailQueryResultDto | null;
  filter: FilterValueDto[] | null;
}
export interface AdminManagerFlowGrid {
  AdminManagerFlowGridResult: AdminManagerFlowQueryResultDto | null;
}

export interface AdminManagerDetailResult {
  AdminManagerDetailResult: Partial<AdminManagerDetail> | null;
}

export interface AdminManagerCreate {
  ResultDtoCreate: AdminManagerDetail | null;
  AdminManagerCreate: AdminManagerDetail | null;
}
export interface AdminManagerEdit {
  ResultDtoEdit: AdminManagerDetail | null;
  AdminManagerEdit: AdminManagerDetail | null;
}

export const GET_ADMIN_MANAGER = "GET_ADMIN_MANAGER";
export const GET_ADMIN_MANAGER_FLOW = "GET_ADMIN_MANAGER_FLOW";
export const GET_ADMIN_MANAGER_DETAIL = "GET_ADMIN_MANAGER_DETAIL";
export const GET_FILTER_ADMIN_MANAGER = "GET_FILTER_ADMIN_MANAGER";
export const CREATE_ADMIN_MANAGER = "CREATE_ADMIN_MANAGER";
export const GET_CREATE_ADMIN_MANAGER = "GET_CREATE_ADMIN_MANAGER";
export const GET_EDIT_ADMIN_MANAGER = "GET_EDIT_ADMIN_MANAGER";
export const EDIT_ADMIN_MANAGER = "EDIT_ADMIN_MANAGER";
export const DELETE_ADMIN_MANAGER = "DELETE_ADMIN_MANAGER";
export const RESUME_SUSPEND_ADMIN_MANAGER = "RESUME_SUSPEND_ADMIN_MANAGER";

// export const dataMockupFlows = [
//   {
//     idFlusso: "responsys_automations",
//     descrizione: "Responsys - Automations",
//   },
//   {
//     idFlusso: "responsys_bulk",
//     descrizione: "Responsys - Bulk",
//   },
// ] as AdminManagerFlow[];

// export const dataMockupBrands = [
//   {
//     idFlusso: "responsys_automations",
//     id: "ray-ban_",
//     brand: "Ray-Ban",
//     //idServerFTP: "MAPR",
//     country: "",
//     ftpOutputTemplate1: "responsys/rayban/unica/IN",
//     ftpOutputTemplate2: "responsys/unica/rayban/dem/OUT",
//     contactHistory: "ROW - /upload/contact_history_row/IN",
//   },
//   {
//     idFlusso: "responsys_automations",
//     id: "sgh_US",
//     brand: "Sunglass Hut",
//     //idServerFTP: "GCP",
//     country: "US",
//     ftpOutputTemplate1: "responsys/sgh/unica/IN",
//     ftpOutputTemplate2: "responsys/unica/sgh/dem/OUT",
//     contactHistory: "ROW - /upload/contact_history/IN",
//   },
//   {
//     idFlusso: "responsys_bulk",
//     id: "ray-ban_",
//     //idServerFTP: "MAPR",
//     brand: "Ray-Ban",
//     country: "",
//     ftpOutputTemplate1: "responsys/rayban/unica/IN",
//     ftpOutputTemplate2: "responsys/unica/rayban/dem/OUT",
//     contactHistory: "ROW - /upload/contact_history_row/IN",
//   },
//   {
//     idFlusso: "responsys_bulk",
//     id: "sgh_IT",
//     brand: "Sunglass Hut Italy",
//     //idServerFTP: "MAPR",
//     country: "IT",
//     ftpOutputTemplate1: "responsys/sgh/unica/IN",
//     ftpOutputTemplate2: "responsys/unica/sgh/dem/OUT",
//     contactHistory: "ROW - /upload/contact_history/IN",
//   },
// ] as AdminManagerDetail[];

// export const dataMockupRenderGrid = [
//   {
//     propertyName: "brand",
//     propertyText: "brand",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 0,
//   },
//   {
//     propertyName: "country",
//     propertyText: "country",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 1,
//   },
//   {
//     propertyName: "idServerFTP",
//     propertyText: "FTP Server ID",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 2,
//   },
//   {
//     propertyName: "ftpOutputTemplate1",
//     propertyText: "FTP Output Template 1",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 3,
//   },
//   {
//     propertyName: "ftpOutputTemplate2",
//     propertyText: "FTP Output Template 2",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 4,
//   },
//   {
//     propertyName: "contactHistory",
//     propertyText: "FTP Output Contact History",
//     show: true,
//     type: 0,
//     ignore: false,
//     order: 5,
//   },
// ] as RenderDetail[];
