import React, { useEffect } from "react";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PageLayout } from "./Components/PageLayout";
import { acquireAccessToken } from "./Redux/Action/AuthenticationAction";
import { Modal } from "react-bootstrap";
import TableContainer from "./Containers/VoucherListContainer";
import SettingsContainer from "./Containers/SettingsContainer";
import Loader from "./Components/Loader";
import ErrorNotification from "./Components/ErrorNotification";
import TableModal from "./screen/VoucherList/VoucherListModal";
import ModalConfirm from "./Components/ModalConfirm";
import { useState } from "react";
import { DataModalConfirm, stateConfirm } from "./Model/Common";
import PaginaCortesia from "./Containers/PaginaCortesia";
import PaginaCortesiaVpn from "./Containers/PaginaCortesiaVpn";
import LogComponent from "./Containers/LogContainer";
import UserManagerContainer from "./Containers/UserManagerContainer";
import AdminManagerContainer from "./Containers/AdminManagerContainer";

interface props {
  instance: any;
}

const App: React.FC<props> = ({ instance }) => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    acquireAccessToken(instance);
  }, [isAuthenticated, instance]);

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
  const [editVoucher, setEditVoucher] = useState<boolean>(false);
  const [idToEdit, setIdToEdit] = useState<string>("");
  //TABS

  const closeModal = (changed: boolean) => {
    if (changed) {
      setConfirm({
        title: "Confirm",
        message: "Are you sure you want to quit? Unsaved changes will be lost",
        button: "Exit",
        item: 0,
        isOpen: true,
        actions: {
          cancel: () => setConfirm(stateConfirm),
          confirm: () => confirmCancel(),
        },
      });
    } else {
      confirmCancel();
    }
  };

  const confirmCancel = () => {
    setIsVisibleModal(false);
    setConfirm(stateConfirm);
    setEditVoucher(false);
  };

  const rtnIsVisibleModal = (val: boolean) => {
    setIsVisibleModal(val);
  };

  const setEdit = (id: string) => {
    setEditVoucher(true);
    setIdToEdit(id);
    setIsVisibleModal(true);
  };

  return (
    <Router>
      <Loader></Loader>
      <div className="versionCollaudo">
        <h5 className="mb-0">Quality Environment</h5>
        <span>VQ 0.2.01</span>
      </div>
      {/* <div className="version">
				VQ 0.1.7
			</div> */}
      <ErrorNotification OnModal={false} />
      <MsalProvider instance={instance}>
        <PageLayout>
          <ModalConfirm data={confirm} />
          <Modal
            show={true}
            dialogClassName="modal-dialog-centered"
            backdropClassName={isVisibleModal ? "" : "hidden"}
            style={{ visibility: `${isVisibleModal ? "visible" : "hidden"}` }}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body className="minH">
              <TableModal
                id={idToEdit}
                edit={editVoucher}
                action={{ closeModal }}
                isVisibleModal={isVisibleModal}
              />
            </Modal.Body>
          </Modal>
          <Switch>
            <Route
              exact
              path="/"
            >
              <TableContainer action={{ rtnIsVisibleModal, setEdit }} />
            </Route>
            <Route
              exact
              path="/settings"
            >
              <SettingsContainer />
            </Route>
            <Route
              exact
              path="/log"
            >
              <LogComponent />
            </Route>
            <Route
              exact
              path="/userManager"
            >
              <UserManagerContainer />
            </Route>
            <Route
              exact
              path="/adminManager"
            >
              <AdminManagerContainer />
            </Route>
            <Route
              exact
              path="/error"
            >
              <PaginaCortesia></PaginaCortesia>
            </Route>
            <Route
              exact
              path="/errorVpn"
            >
              <PaginaCortesiaVpn></PaginaCortesiaVpn>
            </Route>
          </Switch>
        </PageLayout>
      </MsalProvider>
    </Router>
  );
};

export default App;
